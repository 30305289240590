.orderOptionsList {
  text-align: center;

  h1 {
    margin: 6vh 0 0;
    font-size: calc(18px + 0.4vw);
    font-weight: 700;
    text-align: center;
  }

  h2 {
    margin: 0;
    font-size: calc(14px + 0.4vw);
    font-weight: 400;
    text-align: center;
  }
}

.typeList {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: 36px 0;
  padding: 0;

  &.hasDescription {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px 60px;

    li {
      flex: 0 0 calc(50% - 4px);
      width: calc(50% - 4px);
    }

    button {
      padding: 10px 16px 10px 10px;

      &.hasHelp {
        padding-right: 16px;
      }
    }

    .icon {
      flex: 0 0 70px;

      svg {
        font-size: 70px;
      }
    }

    .help {
      top: 5px;
      right: 5px;
      width: 22px;
      height: 22px;
      margin-top: 0;
    }
  }

  li {
    min-width: 15vw;
  }

  button {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 5px 0;
    padding: 6px 12px;
    border: 2px solid #e3e3e3;
    border-radius: 3px;
    background-color: #ffffff;
    line-height: 1;

    &:hover {
      border-color: #0062cc;
    }

    &.hasHelp {
      padding-right: 36px;
    }
  }

  .icon {
    font-size: calc(10px + 0.8vw);
    line-height: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: calc(8px + 0.4vw);
    font-weight: 600;
    text-align: left;
    color: #393939;
  }

  .description {
    margin-top: 10px;
    font-size: calc(6px + 0.4vw);
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    color: #393939;
  }

  .help {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 8px;
    width: 18px;
    height: 18px;
    margin-top: -9px;

    img {
      max-height: 100%;
    }
  }
}

// .paper {
//   margin-left: 1vw;
//   margin-right: 1vw;
//   background-color: #EAEAEA;
//   border: #DEDEDE;
//   border-style: solid;
//   border-width: 2px;
//   min-height: calc(100% - 4px);
// }

// .center {
//   text-align: center;
// }

// .centerHeight {
//   text-align: center;
//   height: 100%;
// }

// .header {
//   font-size: calc(16px + 0.4vw);
//   margin-top: 6vh;
// }

// .subHeader {
//   font-size: calc(14px + 0.4vw);
// }

// .buttonsDiv {
//   margin: 30px 60px;
// }

// .newButton {
//   background-color: #ffffff;
//   color: black;
//   font-size: calc(6px + 0.4vw);
//   text-transform: none;
//   width: 15vw;
//   height: 2vw;
//   min-height: 32px;
//   padding: 2px 6px;
// }

// .newButton:hover {
//   border-color: #0062cc;
// }

// .descriptionButton {
//   border-radius: 4px;
//   border: 2px solid #DEDEDE;
//   background-color: #ffffff;
//   color: black;
//   font-size: 12px;
//   width: 100%;
//   min-height: 120px;
//   padding: 2px 6px;
//   cursor:pointer;
//   text-align: left;
// }

// .descriptionButton:hover {
//   border-color: #0062cc;
// }

// .label {
//   width: 90%;
//   justify-content: left;
// }

// .bold {
//   font-weight: bold;
// }

// .Description {
//   padding: 5px;
// }
